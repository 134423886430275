import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FaIconComponent, FaIconLibrary, IconName, IconPrefix } from '@fortawesome/angular-fontawesome';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Memoized } from '@acetech-development/utilities/core';
import { cache, observeProperty } from '@acetech-development/utilities/rxjs';

const DEFAULT_ICON_PREFIX: IconPrefix = 'far'; // Regular icons

@Component({
  selector: 'app-fa-icon',
  templateUrl: './fa-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    // 3rd
    AsyncPipe,
    FaIconComponent,
  ],
})
export class AppFaIconComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public iconName!: IconName;
  @Input() public iconPrefix: IconPrefix = DEFAULT_ICON_PREFIX;

  private readonly faIconLibrary = inject(FaIconLibrary);

  private readonly subscriptions = new Subscription();

  public ngOnInit(): void {
    this.subscriptions.add(this.validate());
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private validate(): Subscription {
    return combineLatest([
      this.iconPrefix$,
      this.iconName$,
    ]).subscribe(([prefix, name]) => {
      if (!this.isIconRegistered(prefix, name)) {
        // eslint-disable-next-line no-console
        console.error(`Icon '${prefix}-${name}' not found in FaIconLibrary!`);
      }
    });
  }

  private isIconRegistered(prefix: IconPrefix, name: IconName): boolean {
    return !!this.faIconLibrary.getIconDefinition(prefix, name);
  }

  @Memoized
  public get iconName$(): Observable<IconName> {
    return observeProperty(this, 'iconName').pipe(
      cache(),
    );
  }

  @Memoized
  public get iconPrefix$(): Observable<IconPrefix> {
    return observeProperty(this, 'iconPrefix').pipe(
      cache(),
    );
  }
}
